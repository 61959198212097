@import "variables.scss";
@import "default.scss";

body {
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
}

* {
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
}

.score {
    position: fixed;
    top: 0;
    right: 0;
    background: yellow;
    z-index: 1000;
    color: black;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    width: 5em;
    height: 2.2em;
    border-radius: 0.2em;
    min-width: 5.3em;
}

.info {
    position: fixed;
    top: 0;
    left: 0;
    background: #161616;
    z-index: 1000;
    color: black;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    height: 2.2em;
    border-radius: 0.2em;
    color: white;
    text-decoration: none;
    opacity: 0.7;
}

.time {
    position: fixed;
    top: 0;
    right: 50%;
    transform: translate(50%,0);
    background: #3c1d6b;
    z-index: 1000;
    color: white;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    height: 2.2em;
    border-radius: 500em;
    min-width: 3em;
    filter: drop-shadow(0rem 0rem 0.5rem rgb(0, 0, 0));
}

.results {
    position: fixed;
    z-index: 1000;
    color: black;
    padding: 5em;
    text-align: center;
    border-radius: 0.2em;
    font-size: 1em;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(0.1rem);
    




    img {
        object-fit: contain;
        position: relative;
        z-index: 910;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: drop-shadow(0rem 0rem 1rem rgb(0, 0, 0));
    }
    
    filter: drop-shadow(0rem 0rem 1rem rgb(0, 0, 0));
}

.result-text {
    z-index: 990;
    position: relative;
}

.results-score {
    font-size: 4em;
}

.loading-game{
    z-index: 1010;
    position: fixed;
    // top: 50%;
    // right: 50%;
    // transform: translate(50%,-50%);
    padding-top: 4em;
    background: black;
    backdrop-filter: blur(0.1rem);
    color: white;
    padding: 1em;
    text-align: center;
    width: 100%;
    height: 100%;
    transition: 0.5s all;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation:spin 12s linear infinite;
    }
}

.game-loading-text {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #3c1d6b;
    background: white;
    color: #3c1d6b;
    filter: drop-shadow(0rem 0rem 0.2rem rgb(0, 0, 0));
    border-radius: 2em;
    z-index: 1020;
    padding: 1em;
}

.game-about-play {
    position: fixed;
    top: 1em;
    left: 50%;
    transform: translate(-50%,0);
    background: #3c1d6b;
    background: white;
    color: black;
    filter: drop-shadow(0rem 0rem 0.2rem rgb(0, 0, 0));
    border-radius: 2em;
    z-index: 1020;
    padding: 1em;
    text-decoration: none;
}

.game-about-play:hover {
    opacity: 0.5;
    transition: 0.5s all;
}

.js-hide-loading {
    opacity: 0
}

.js-remove-loading {
    display: none;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.game-title {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.5em;
    text-align: left;
    z-index: 1050;
    filter: drop-shadow(0rem 0rem 0.5rem rgb(0, 0, 0));

    h1 {
        display: inline-block;
    }

    h1, div{
        font-size: inherit;
        font-weight: normal;
        margin: 0;
        padding: 0;
        // background: #3c1d6b;
        padding: 1em;
        border-radius: 2em;
    }

    div {
        border-radius: 0.2em;
        // background: black;
    }
}
.game-about {
    // overflow: scroll;
    // position: fixed;
    // top: 0;
    // left: 0;
    padding: 0.5em;
    text-align: left;
    z-index: 1050;
    filter: drop-shadow(0rem 0rem 0.5rem rgb(0, 0, 0));
    width: 50%;
    min-height: 100%;
    // border-right: 1px solid white;

    img {
        width: 30em;
        max-width: 50%;
    }

    h1 {
        display: inline-block;
    }

    h1, div{
        font-size: inherit;
        font-weight: normal;
        margin: 0;
        padding: 0;
        // background: #3c1d6b;
        padding: 1em;
        border-radius: 2em;
    }

    div {
        border-radius: 0.2em;
        // background: black;
    }
}

.about-page {
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.game-about-images {
    position: absolute;
    top: 0;
    left: 50%;
    // background: red;
    width: 50%;

    img {
        animation:spin 12s linear infinite;
        width: 100%;
        padding: 2em;
    }
}


.js-hidden {
    display: none;
}

.results-score {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #3c1d6b;
    background: white;
    color: #3c1d6b;
    filter: drop-shadow(0rem 0rem 0.2rem rgb(0, 0, 0));
    border-radius: 2em;
    z-index: 1020;
    padding: 1em;
}

.result-instructions {
    margin-top: 2em;
    font-size: 0.5em;
    color: white;
    // opacity: 0.5;
}

.full {
    position: fixed;
    top: 2.5em;
    right: 0;
    background: burlywood;
    z-index: 1000;
    color: black;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    // width: 5em;
    height: 2.2em;
    border-radius: 0.2em;
    opacity: 0.5;
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.control {
    position: fixed;
    bottom: 0.5em;
    left: 0,5em;
    z-index: 1000;
}

.up, .left, .right {
    width: 5em;
    height: 5em;
    text-align: center;
    background: yellow;;
    vertical-align: middle;
    line-height: 5em;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
    border-radius: 0.5em;
    color: brown;

}
.up {
    width: 5em;
    height: 5em;
    background: cadetblue;
    text-align: center;
}

.hidden {
    display:none
}

.left {
    position: fixed;
    bottom: 0.5em;
    left: 0.5em;
    z-index: 1000;
}

.right {
    position: fixed;
    bottom: 0.5em;
    left: 5.7em;
    z-index: 1000;
}

.up {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    z-index: 1000;
}


.about-small {
    // font-size: 0.87em !important;
    margin-top: 5em !important;
    padding-bottom: 0 !important;
}